<template>
  <div class="card">
    <div class="card-content">
      <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <div>Powered by</div>
        <a href="https://appointr.app" target="_blank">
          <figure class="media-left">
            <p class="image">
              <img src="../assets/appointr-logo.png" alt="appointr logo" class="logo">
            </p>
          </figure>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.card {
  border: 1px solid #dbdbdb;
}

.logo {
  width: 102px;
}
</style>
