export default {
  "online_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online booking"])},
  "view_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View availability right away and book your appointment!"])},
  "make_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book appointment"])},
  "cancellation_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation policy"])},
  "cancellation_terms_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["You can't cancel your appointment"]), _normalize(["You can cancel your appointment up to ", _interpolate(_named("hours")), " hour before the appointment."]), _normalize(["You can cancel your appointment up to ", _interpolate(_named("hours")), " hours before the appointment."])])},
  "opening_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours"])},
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "your_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your appointment"])},
  "which_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which service do you want?"])},
  "click_on_service_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a service to select it."])},
  "service_duration_mins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " minutes"])},
  "price_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starting from"])},
  "no_service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no description for this service."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "which_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose provider"])},
  "choose_employee_for_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose for me"])},
  "with_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With"])},
  "select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
  "select_timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select appointment time"])},
  "no_timeslots_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No availability for this day"])},
  "choose_another_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can try another day"])},
  "until_approx": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["until appr. ", _interpolate(_named("time"))])},
  "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
  "connect_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must connect with one of the following ways to complete your booking"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "register_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register with email"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
  "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email_required"])},
  "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name_required"])},
  "phone_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone_required"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile"])},
  "mobile_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile_required"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm appointment"])},
  "no_service_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't selected a service yet."])},
  "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
  "accept_privacy_policy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I accept the ", _interpolate(_named("tos")), " and ", _interpolate(_named("privacy_policy"))])},
  "privacy_policy_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the terms of service to continue"])},
  "confirm_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your appointment"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your appointment has been confirmed"])},
  "confirmation_has_been_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A confirmation email with your appointment details has been sent to your email"])},
  "back_to_homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
  "appointment_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately the selected time is no longer available. Click below to select another one."])},
  "change_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change time"])},
  "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms of service"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "no_announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no announcements"])},
  "cancel_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel appointment"])},
  "appointment_could_not_be_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The appointment could not be cancelled. Please try again"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "cancel_appointment_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to cancel your appointment on ", _interpolate(_named("date")), " at ", _interpolate(_named("time")), "?"])},
  "appointment_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment was cancelled"])},
  "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning"])},
  "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evening"])},
  "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afternoon"])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night"])},
  "cannot_cancel_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot cancel this appointment"])},
  "no_appointment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No appointment found"])},
  "verification_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification email sent"])},
  "enter_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your email and enter the 6-digit code below"])},
  "verification_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification failed. Try again."])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "appointment_not_allowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your booking has failed. Please contact us at ", _interpolate(_named("phone")), "."])},
  "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "mobile_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example:"])},
  "country_code_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
  "Austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
  "Belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
  "Bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
  "Croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
  "Cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
  "Czech Republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
  "Denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
  "Estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
  "Finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
  "France": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
  "Germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
  "Greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
  "Hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
  "Ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
  "Italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
  "Latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
  "Lithuania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
  "Luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
  "Malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
  "Netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
  "Poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
  "Portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
  "Romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
  "Slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
  "Slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
  "Spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
  "Sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
  "Switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
  "United States of America": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States of America"])},
  "Great Britain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great Britain"])},
  "Russia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russia"])},
  "Albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
  "Serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
  "Ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
  "North Macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Macedonia"])},
  "phone_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number is invalid"])},
  "install_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app takes no space on your device and provides you with a better experience."])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install"])},
  "install_prompt_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install Booking Page"])},
  "add_your_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment"])},
  "confirm_your_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your booking details, add a comment if you wish and press Confirm to complete your booking"])},
  "booking_is_not_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking is not yet complete"])},
  "booking_page_currently_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking page currently unavailable"])},
  "booking_page_currently_unavailable_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The booking page you are looking for has probably been disabled by the owner and is temporarily unavailable."])},
  "go_to_appointr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Appointr"])}
}