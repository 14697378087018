export default {
  "validations": {
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Το ", _interpolate(_named("property")), " είναι υποχρεωτικό."])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Το ", _interpolate(_named("property")), " πεδία έχει τιμή '", _interpolate(_named("model")), "', αλλά πρέπει να είναι τουλάχιστον ", _interpolate(_named("min")), " χαρακτήρες."])}
  },
  "online_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online ραντεβού"])},
  "view_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δες σε πραγματικό χρόνο τη διαθεσιμότητα και κλείσε το ραντεβού σου!"])},
  "make_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλείσε ραντεβού"])},
  "cancellation_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όροι ακύρωσης"])},
  "cancellation_terms_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Δεν μπορείς να ακυρώσεις την κράτησή σου"]), _normalize(["Θα μπορείς να ακυρώσεις την κράτησή σου έως και ", _interpolate(_named("hours")), " ώρα πριν από το ραντεβού σου."]), _normalize(["Θα μπορείς να ακυρώσεις την κράτησή σου έως και ", _interpolate(_named("hours")), " ώρες πριν από το ραντεβού σου."])])},
  "opening_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρες λειτουργίας"])},
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δευτέρα"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τετάρτη"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πέμπτη"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρασκευή"])},
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σάββατο"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κυριακή"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλειστά"])},
  "your_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κράτηση σου"])},
  "which_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τι υπηρεσία χρειάζεσαι;"])},
  "click_on_service_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πάτησε πάνω στην υπηρεσία για να την επιλέξεις."])},
  "service_duration_mins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " λεπτά"])},
  "price_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["από"])},
  "no_service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει διαθέσιμη περιγραφή γι'αυτή την υπηρεσία."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίσω"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επόμενο"])},
  "which_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε πάροχο"])},
  "choose_employee_for_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε εσείς για μένα"])},
  "with_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την/τον υπάλληλο"])},
  "select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ημερομηνία"])},
  "select_timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ώρα του ραντεβού σου"])},
  "no_timeslots_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει διαθεσιμότητα για αυτήν την ημέρα"])},
  "choose_another_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείς να δοκιμάσεις μια από τις επόμενες ημερομηνίες"])},
  "until_approx": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["περίπου μέχρι τις ", _interpolate(_named("time"))])},
  "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέσου"])},
  "connect_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα πρέπει να συνδεθείς με έναν από τους παρακάτω τρόπους για να ολοκληρωθεί η κράτησή σου."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ονοματεπώνυμο"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "register_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση με email"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
  "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το email είναι υποχρεωτικό"])},
  "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όνομα είναι υποχρεωτικό"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κινητό τηλέφωνο"])},
  "mobile_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κινητό τηλέφωνο είναι υποχρεωτικό"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])},
  "no_service_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχεις επιλέξει ακόμα κάποια υπηρεσία."])},
  "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανακοινώσεις"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["πολιτική απορρήτου"])},
  "accept_privacy_policy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αποδέχομαι τους ", _interpolate(_named("tos")), " και την ", _interpolate(_named("privacy_policy"))])},
  "privacy_policy_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να αποδεχθείς τους όρους χρήσης για να συνεχίσεις"])},
  "confirm_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαιώστε την κράτηση σας"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tο ραντεβού σου καταχωρήθηκε με επιτυχία"])},
  "confirmation_has_been_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχει σταλεί ένα μήνυμα στο email σου, με την επιβεβαίωση και τις λεπτομέρειες του ραντεβού σου."])},
  "back_to_homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίσω στην αρχική"])},
  "appointment_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συγκεκριμένη ώρα δεν είναι πλέον διαθέσιμη. Πατήστε για να επιλέξετε κάποια άλλη ώρα."])},
  "change_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή ώρας"])},
  "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["όρους χρήσης"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή χρήστη"])},
  "no_announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ανακοινώσεις"])},
  "cancel_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση ραντεβού"])},
  "appointment_could_not_be_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ραντεβού δεν ακυρώθηκε. Προσπάθησε ξανά"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
  "cancel_appointment_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Είσαι σίγουρος ότι θέλεις να ακυρώσεις το ραντεβού σου για την ", _interpolate(_named("date")), " στις ", _interpolate(_named("time")), ";"])},
  "appointment_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ραντεβού ακυρώθηκε. Κλείσε τώρα ένα νέο ραντεβού"])},
  "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρωί"])},
  "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόγευμα"])},
  "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεσημέρι"])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βράδυ"])},
  "cannot_cancel_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το ραντεβού δεν μπορεί να ακυρωθεί. Επικοινώνησε με το κατάστημα"])},
  "no_appointment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε ραντεβού"])},
  "verification_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχει σταλεί επιβεβαιωτικό email"])},
  "enter_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε έλεγξε το λογαριασμό ηλεκτρονικού ταχυδρομείου σου και πληκτρολόγησε τον 6-ψηφιο κωδικό που έχεις λάβει."])},
  "verification_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επιβεβαίωση απέτυχε. Δοκίμασε ξανά."])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επώνυμο"])},
  "appointment_not_allowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η κράτηση απέτυχε. Επικοινώνησε μαζί μας στο ", _interpolate(_named("phone")), "."])},
  "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα"])},
  "mobile_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["π.χ.:"])},
  "country_code_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε χώρα"])},
  "Austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυστρία"])},
  "Belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βέλγιο"])},
  "Bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βουλγαρία"])},
  "Croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κροατία"])},
  "Cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κύπρος"])},
  "Czech Republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τσεχία"])},
  "Denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δανία"])},
  "Estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εσθονία"])},
  "Finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φινλανδία"])},
  "France": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γαλλία"])},
  "Germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γερμανία"])},
  "Greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελλάδα"])},
  "Hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ουγγαρία"])},
  "Ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιρλανδία"])},
  "Italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιταλία"])},
  "Latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λετονία"])},
  "Lithuania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λιθουανία"])},
  "Luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λουξεμβούργο"])},
  "Malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάλτα"])},
  "Netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολλανδία"])},
  "Poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολωνία"])},
  "Portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πορτογαλία"])},
  "Romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρουμανία"])},
  "Slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σλοβακία"])},
  "Slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σλοβενία"])},
  "Spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ισπανία"])},
  "Sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σουηδία"])},
  "Switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελβετία"])},
  "United States of America": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η.Π.Α."])},
  "Great Britain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεγάλη Βρετανία"])},
  "Russia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρωσία"])},
  "Albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλβανία"])},
  "Serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σερβία"])},
  "Ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ουκρανία"])},
  "North Macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βόρεια Μακεδονία"])},
  "phone_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο αριθμός είναι λανθασμένος"])},
  "install_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εφαρμογή δεν πιάνει χώρο στη συσκευή σου και προσφέρει μια καλύτερη εμπειρία."])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκατάσταση"])},
  "install_prompt_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκατάσταση Booking Page"])},
  "add_your_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσθεσε ένα σχόλιο"])},
  "confirm_your_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγξε τα στοιχεία της κράτησής σου, πρόσθεσε ένα σχόλιο αν το επιθυμείς και πάτησε Επιβεβαίωση για να ολοκληρωθεί η κράτηση"])},
  "booking_is_not_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κράτησή σου δεν έχει ακόμα ολοκληρωθεί"])},
  "booking_page_currently_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σελίδα δεν είναι διαθέσιμη αυτή τη στιγμή."])},
  "booking_page_currently_unavailable_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σελίδα που αναζητάτε ενδέχεται να έχει απενεργοποιηθεί από τον διαχειριστή και είναι προσωρινά μη διαθέσιμη."])},
  "go_to_appointr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετάβαση στο Appointr"])}
}